import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'jquery-sticky';
import Sticky from 'sticky-js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './src/styles/main.css';

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    import('jquery-sticky').then(() => {
      $(".js-sticky-header").sticky({ topSpacing: 0 });

      const Sticky = require('sticky-js');
      new Sticky('.menu');
    });
    require('bootstrap/dist/js/bootstrap.bundle.min');
  }
};
